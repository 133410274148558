import React, { useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import { userContext } from '../../router/AppRouter';
import { NavBar } from '../ui/NavBar';
import { Pie } from "../ui/Pie"

export const InfoScreen = () => {
  sessionStorage.clear()
  
  const { setLogging } = useContext(userContext)

  useEffect(() => {
    setLogging(false)
  }, [setLogging])
  return (
    <div className='landing'>
      <NavBar />
      <div className='banner'>
        <div className='text-light px-5 py-4 text-center animate__animated animate__fadeIn'>
          <Link to="/" style={{ "textDecoration": "none" }} className="text-light">
            <h1 className='letra1'> Centro de estudio personalizado </h1>
            <h1 className='letra1'> Matemáticas con Jimmy </h1>
          </Link>

          <p className='my-4 fs-5' > Información </p>
          <ion-icon name="information-circle" color="light" size="large"></ion-icon>
        </div>
      </div>

      <h3 className='text-center my-3 fw-bold text-light' style={{ "fontFamily": "cambria" }}> Nuestros servicios </h3>


      <div className='row  animate__animated animate__fadeIn m-4 text-light'>
        <div className='col-md-6 col-sm-12 p-3'>
          <h4 className='text-center text-light p-3 rounded-pill fw-bold letra1' style={{ "background": "#063254" }}> Nivelación Academica </h4>
          <div className='d-flex justify-content-center'>
            <img src={"https://images.unsplash.com/photo-1581726707445-75cbe4efc586?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80"} alt="academica" className='card-img cardinfo p-3' />

          </div>
          <h5 > Se brinda reforzamiento en los cursos de:  </h5>
          <ul className='fs-5 px-5'>
            <li className='my-2'> Razonamiento matemático </li>
            <li className='my-2'> Álgebra </li>
            <li className='my-2'> Aritmética </li>
            <li className='my-2'> Geometría </li>
            <li className='my-2'> Trigonometría </li>
            <li className='my-2'> Física </li>
            <li className='my-2'> Química </li>
          </ul>
          <p className='fs-5'>
            para primero, segundo, tercero, cuarto y quinto de secundaria.

          </p>
        </div>

        <div className='col-md-6 col-sm-12 p-3'>
          <h4 className='text-center text-light p-3 rounded-pill fw-bold letra1' style={{ "background": "#063254" }}> Preparación pre - universitaria </h4>
          <div className=''>
            <img src={"https://images.unsplash.com/photo-1567168544999-b8ca00a3d9c2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"} alt="universitaria" className='card-img p-3 cardinfo' />
          </div>

          <h5> Se brinda preparación para las universidades:  </h5>
          <ul className='fs-5 px-5'>
            <li className='my-2'> Universidad Andina del Cusco <a target="_blank" rel="noopener noreferrer" href="https://www.uandina.edu.pe/sobre-admision/"> ir a admision...</a></li>
            <li className='my-2'> Universidad Nacional de San Antonio Abad del Cusco <a target="_blank" rel="noopener noreferrer" href="http://admision.unsaac.edu.pe/"> ir a admision...</a> </li>
            <li className='my-2'> Pontificia Universidad Católica del Perú <a target="_blank" rel="noopener noreferrer" href="https://www.pucp.edu.pe/admision/"> ir a admision...</a> </li>
            <li className='my-2'> Universidad Católica de Santa María <a target="_blank" rel="noopener noreferrer" href="https://www.ucsm.edu.pe/admision-pregrado/"> ir a admision...</a></li>
            <li className='my-2'> Universidad Peruana Cayetano Heredia <a target="_blank" rel="noopener noreferrer" href="https://www.cayetano.edu.pe/cayetano/es/admision"> ir a admision...</a> </li>
            <li className='my-2'> Escuela Militar de Chorrillos <a target="_blank" rel="noopener noreferrer" href="https://www.escuelamilitar.edu.pe/admision"> ir a admision...</a></li>
            <li className='my-2'> Universidad Privada Antenor Orrego <a target="_blank" rel="noopener noreferrer" href="https://upao.edu.pe/vacantes-semestre/oficina-admision/"> ir a admision...</a></li>
            <li className='my-2'> Universidad Peruana de Ciencias Aplicadas <a target="_blank" rel="noopener noreferrer" href="https://www.upc.edu.pe/admision/?utm_source=Organico&utm_medium=flotante-admision"> ir a admision...</a></li>
            <li className='my-2'> Universidad Nacional de Ingeniería <a target="_blank" rel="noopener noreferrer" href="http://admision.uni.edu.pe/"> ir a admision...</a></li>
            <li className='my-2'> Universidad Nacional Mayor de San Marcos <a target="_blank" rel="noopener noreferrer" href="https://unmsm.edu.pe/formacion-academica/admision"> ir a admision...</a></li>
            <li className='my-2'> Universidad Nacional Federico Villareal <a target="_blank" rel="noopener noreferrer" href="https://web2.unfv.edu.pe/Sitio/admision"> ir a admision...</a></li>
          </ul>
          <p className='fs-5'> y otras mas... </p>
        </div>

        <div className='col-12 p-3 animate__animated animate__fadeIn'>
          <h4 className='text-center text-light p-3 rounded-pill' style={{ "background": "#063254" }}> Aula virtual </h4>

          <p className='fs-4'> Tanto estudiantes como padres pueden estar informados acerca de la actividad académica. Además, tendrán acceso a material académico actualizado según el prospecto de admisión de cada universidad para complementar su preparación. </p>
          <div className='text-center'>
            <Link to={"/aulavirtual"} className="btn btn-primary my-3 colorprim text-light px-4 py-2 fs-5 letra1"> Ir a aula virtual </Link>
          </div>
        </div>
      </div>
      <Pie />
    </div>
  )
}
