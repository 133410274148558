import React, { useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import { userContext } from '../../router/AppRouter';
import { NavBar } from '../ui/NavBar';
import { Pie } from "../ui/Pie"

export const ErrorScreen = () => {
  sessionStorage.clear()
  
  const { setLogging } = useContext(userContext)

  useEffect(() => {
    setLogging(false)
  }, [setLogging])
  return (
    <div className='landing'>
      <NavBar />
      <div className='banner'>
        <div className='text-light px-4 py-4 text-center animate__animated animate__fadeIn'>
          <Link to="/" style={{ "textDecoration": "none" }} className="text-light">
            <h1 className='letra1'> Centro de estudio personalizado </h1>
            <h1 className='letra1'> Matemáticas con Jimmy </h1>
          </Link>

          <p className='my-4 fs-5'> Brindamos nivelación académica, preparación para las universidades UAC, UPCH, UNSAAC, PUCP, UCSM y otras universidades de Lima. </p>

        </div>

        <div className='text-center animate__animated animate__fadeIn'>
          <Link to={"/info"} className="btn btn-info text-light px-4 py-2 fs-5 letra1 buttonInfo"> + Info </Link>
        </div>
      </div>
      <div className='text-center m-5 p-5 text-light'>
        <h1 style={{ "fontFamily": "helvetica" }}>404Error!!!</h1>
        <p className='text-muted fs-5'> No se ha encontrado la página que esta buscando </p>
      </div>
      <Pie />
    </div>
  )
}
