import React, { useEffect, useState } from 'react'
import { getMaterialByUsuarioRequest, getMaterialRequest, getUsuarioRequest } from '../../api/usuario.api'
import { Modalpdf } from './Modalpdf'

export const Material = () => {

  const [material, setMaterial] = useState([])
  const [fichas, setFichas] = useState("")

  useEffect(() => {
    const getServ = async () => {
      const user = await getUsuarioRequest(sessionStorage.getItem("i"))
      if (user.data.servicio === "6p") {
        const getMat6to = async () => {
          const mat = await getMaterialRequest()
          const sexto = mat.data.filter(
            (mat) => mat.servicio === "6p")
          setMaterial(sexto)
        }
        getMat6to()
      }
      else {
        const getCurso = async () => {
          const response = await getMaterialByUsuarioRequest(sessionStorage.getItem("i"))
          setMaterial(response.data)
        }
        getCurso()
      }
    }
    getServ()


  }, [])



  const filteredFichas = material.filter(
    (mat) => mat.servicio.toLowerCase().includes(fichas.toLowerCase())
  )

  return (
    <div>
      <h4 className='mx-2 mb-3 fst-italic'> Filtrar por servicio: </h4>

      <div className="px-4">
        <span className='text-muted'> Ingrese el nombre del curso o el numero de grado de reforzamiento, ejemplo: si busca pre - 3 ingrese 3. </span>
        <input type="text" placeholder="Busca por servicio..." className="form-control mt-3 mb-2 border border-primary px-4"
          onChange={e => setFichas(e.target.value)}
        />
      </div>

      <table className="table table-success table-hover table-striped">
        <thead>
          <tr className="text-center">
            <th> modalidad </th>
            <th> servicio </th>
            <th> descripción </th>
            <th> material </th>
          </tr>
        </thead>
        <tbody>

          {
            filteredFichas.map((mat) => (

              <tr className="text-center" key={mat.idMaterial}>
                <td data-title="modalidad:"> {mat.modalidad} </td>
                <td data-title="servicio:"> {mat.servicio} </td>
                <td data-title="descripción:"> {mat.descripcion} </td>

                <td data-title="archivo:" > <button type="button" className="btn btn-outline-primary mx-2" data-bs-toggle="modal" data-bs-target={`#id${mat.idMaterial}`}><i className="fa-solid fa-book"> </i></button> </td>

                <Modalpdf id={`id${mat.idMaterial}`} archivo={`https://mjback-railway-production.up.railway.app/${mat.idMaterial}-${mat.modalidad}-${mat.servicio}.pdf`} />

              </tr>

            ))

          }

        </tbody>
      </table>
    </div>

  )
}
