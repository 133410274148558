import React from 'react'

export const Pie = () => {
  return (
    <div className='text-center colorprim py-2'>
      <div className='mx-3 animate__animated animate__fadeIn'>
        <h5 className='text-light my-3'> Ubícanos en nuestras redes sociales!! </h5>
        <div className='d-flex justify-content-center'>

          <a className='mx-3' target="_blank" rel="noreferrer noopener" href='https://www.facebook.com/profile.php?id=100057162261614'> <ion-icon name="logo-facebook" color="light" size="large"></ion-icon> </a>

          <a className='mx-3' target="_blank" rel="noreferrer noopener" href='https://wa.me/51941018206?text=Hola!,%20%20%C2%BFpueden%20darme%20mas%20informaci%C3%B3n%20sobre%20el%20Centro%20de%20Estudio%20Personalizado%20%22Matem%C3%A1ticas%20con%20Jimmy%22?'> <ion-icon name="logo-whatsapp" color="light" size="large"></ion-icon></a>

          <a className='mx-3' target="_blank" rel="noreferrer noopener" href='https://www.facebook.com/profile.php?id=100057162261614'><ion-icon name="logo-instagram" color="light" size="large"></ion-icon></a>

        </div>
      </div>

      <div className='mt-3 mb-2'>
      <h6 className='text-light'> jimmytapara@gmail.com</h6>
        <span className='text-light'> Copyright © 2022 Matemáticas con Jimmy</span><br />
      
      </div>
    </div>
  )
}
