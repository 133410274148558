import React from 'react'
import { Link, NavLink, useNavigate} from 'react-router-dom'
import swal from "sweetalert"



export const NavAdmin = ({name}) => {
    const navigate = useNavigate()
    const onLogout = () => {
        swal({
            title: "Cerrar sesión",
            text: "¿Esta seguro que desea cerrar sesión?",
            icon: "warning",
            buttons: ["No", "Si"]
          }).then(async res => {
            if (res) {
                
                navigate("/aulavirtual")
                localStorage.removeItem("auth")
                localStorage.removeItem("name")
            }
            else {
      
            }
          })
    }

    return (
        <>

            <nav className="navbar navbar-expand-md navbar-dark sticky-top colorprim">
                <Link
                    className="text-light navbar-brand mx-3"
                    to="/aulavirtual/admin/alumnos"
                >
                    Admin
                </Link>

                <button
                    className='navbar-toggler'
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#menu"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="true"
                    aria-label="Toggle navigation"
                >
                    <span className='navbar-toggler-icon'></span>

                </button>

                <div className='collapse navbar-collapse ' id="menu">
                    <ul className='navbar-nav text-center'>
                        <li className='nav-item mx-4 px-1'>
                            <NavLink
                                className={({ isActive }) => "nav-item nav-link " + (isActive ? "active" : "")}
                                to="/aulavirtual/admin/alumnos"
                            >
                                Alumnos
                            </NavLink>
                        </li>
                        <li className='nav-item mx-4 px-1'>
                            <NavLink
                                className={({ isActive }) => "nav-item nav-link " + (isActive ? "active" : "")}
                                to="/aulavirtual/admin/material"
                            >
                                Material
                            </NavLink>
                        </li>
                        <li className='nav-item mx-4 px-1'>
                            <NavLink
                                className={({ isActive }) => "nav-item nav-link " + (isActive ? "active" : "")}
                                to="/aulavirtual/admin/asistencia"
                            >
                                Asistencia
                            </NavLink>
                        </li>
                        <li className='nav-item mx-4 px-1'>
                            <NavLink
                                className={({ isActive }) => "nav-item nav-link " + (isActive ? "active" : "")}
                                to="/aulavirtual/admin/notas"
                            >
                                Notas
                            </NavLink>
                        </li>

                    </ul>
                </div>

                <div id="menu" className='navbar-collapse collapse justify-content-end'>
                    <ul className='navbar-nav px-1'>
                    

                        <span className='nav-item align-self-center text-light'>
                            {sessionStorage.getItem("name")}
                        </span>
                        <button onClick={()=> onLogout()} className=' mx-3 nav-item  btn btn-danger'>
                            Logout
                        </button>
                    </ul>
                </div>

            </nav>
        </>

    )
}
