import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { deleteAsistenciaRequest, getAsistenciaByUsuarioRequest } from '../../api/usuario.api'
import swal from "sweetalert"
import moment from "moment"

export const AsistenciaDetalle = () => {
    const params = useParams()
    const [asistencia, setAsistencia] = useState([])
    useEffect(() => {
        loadAsistencia()
    })


    const loadAsistencia = async () => {
        const response = await getAsistenciaByUsuarioRequest(params.id)
        setAsistencia(response.data)
        
    }

    const deleteAsistencia = (id) => {
        swal({
            title: "Borrar asistencia",
            text: "Esta seguro que desea eliminar este registro?",
            icon: "warning",
            buttons: ["No", "Si"]
        }).then(async res => {
            if (res) {
                const response = await deleteAsistenciaRequest(id)
                console.log(response)
                swal({ text: "se ha eliminado el alumno", icon: "success" })
                loadAsistencia()
            }
            else {

            }
        })

    }

    return (
        <div>
        <h4 className='mx-2 my-3 fst-italic'> Registro de asistencia: </h4>
            <table className="table table-success table-hover table-striped">
                <thead>
                    <tr className="text-center">
                        <th> fecha </th>
                        <th> turno </th>
                        <th> estado </th>
                        <th> justificacion </th>
                    </tr>
                </thead>
                <tbody>

                    {
                        asistencia.map((asis) => (
                            <tr className="text-center" key={asis.idAsistencia}>
                                <td data-title="fecha:"> {moment(asis.fecha).utc().format("DD/MM/YYYY")} </td>
                                <td data-title="turno:"> {asis.turno} </td>
                                <td data-title="estado:"> {asis.estado} </td>
                                <td data-title="justificacion:"> {asis.justificacion} </td>
                                <td className="px-1">
                                    <button onClick={() => deleteAsistencia(asis.idAsistencia)} className="btn btn-danger"> <i className="fa-solid fa-trash-can "></i> </button>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </div>
    )
}
