import React, { useEffect, useState } from 'react'
import moment from "moment"
import { getLastComunicadoRequest, getUsuarioRequest } from '../../api/usuario.api'
import { ComunicadosAlumno } from './ComunicadosAlumno'
import { ComPersonal } from './ComPersonal'

export const Perfil = () => {

  const [barra, setBarra] = useState(false)
  const [porcentaje, setPorcentaje] = useState(0)
  const changeBackground = () => {
    setBarra(true)

  }

  const [lastComunicado, setLastComunicado] = useState({})

  useEffect(() => {
    loadLastComunicado()
  }, [])


  const loadLastComunicado = async () => {
    const response = await getLastComunicadoRequest()
    setLastComunicado(response.data)
  }



  useEffect(() => {

    changeBackground()
  }, [])

  useEffect(() => {
    const getAlumnoById = async () => {

      const response = await getUsuarioRequest(sessionStorage.getItem("i"))
      getProgress(response.data.fechaInicio, response.data.fechaFin)
      setAlumno({
        nombre: response.data.nombre,
        apellidos: response.data.apellidos,
        modalidad: response.data.modalidad,
        servicio: response.data.servicio,
        fechaInicio: moment(response.data.fechaInicio).utc().format("DD/MM/YYYY"),
        fechaFin: moment(response.data.fechaFin).utc().format("DD/MM/YYYY"),
        telefono: response.data.telefono,
        telefonoApoderado: response.data.telefonoApoderado,
        correo: response.data.correo,
        pago: response.data.pago,
        contrasena: response.data.contrasena,
        algebra: (response.data.algebra) ? true : false,
        aritmetica: (response.data.aritmetica) ? true : false,
        geometria: (response.data.geometria) ? true : false,
        trigonometria: (response.data.trigonometria) ? true : false,
        fisica: (response.data.fisica) ? true : false,
        quimica: (response.data.quimica) ? true : false,
        rm: (response.data.rm) ? true : false,
        biologia: (response.data.biologia) ? true : false,
        horarios: response.data.horarios,
        manana: (response.data.manana) ? true : false,
        tarde: (response.data.tarde) ? true : false,
        comunicado: response.data.comunicado
      })
    }
    getAlumnoById()
  }, [])




  const [alumno, setAlumno] = useState({
    nombre: "",
    apellidos: "",
    modalidad: "",
    servicio: "",
    fechaInicio: "",
    fechaFin: "",
    telefono: "",
    telefonoApoderado: "",
    correo: "",
    contrasena: "",
    horarios: "",
    pago: "",
    manana: false,
    tarde: false,
    algebra: false,
    aritmetica: false,
    geometria: false,
    trigonometria: false,
    fisica: false,
    quimica: false,
    rm: false,
    biologia: false,
    comunicado: ""

  })

  const getProgress = (feInicio, feFin) => {
    const Fin = moment(feFin)
    const Inicio = moment(feInicio)
    const total = Fin.diff(Inicio, "days")
    const actual = moment()
    const avance = actual.diff(Inicio, "days")
    const porcentaje = (100 * avance) / total
    setPorcentaje(parseInt(porcentaje))
  }


  return (
    <div>
      <div className='m-4'>
        <h5> {`Bienvenid@ denuevo ${sessionStorage.getItem("name")}!!`} </h5>
        {
          lastComunicado ? <ComunicadosAlumno lastComunicado={lastComunicado} /> : ""
        }
        {
          alumno.comunicado === null || alumno.comunicado === ""
            ? ""
            : <ComPersonal comunicado={alumno.comunicado} />
        }
      </div>


      <div className='mx-4'>

        <div className="card text-center cardAlumno mb-2 letra">
          <div className="card-body">
            <div className='container m-0'>
              <div className='row'>
                <div className='col-lg-5 col-sm-12'>
                  <h5 className="card-title fs-4 fw-bold ">Datos del alumno</h5>
                  <p className='my-1 fw-bold letraPerfil'>{alumno.modalidad === "PRE" ? "Preparación pre universitaria" : "Reforzamiento académico"}</p>
                  {
                    alumno.modalidad === "PRE"
                      ? <i className="fa-solid fa-building-columns avUser m-2 animate__animated animate__flipInY" ></i>
                      : <i className="fa-solid fa-school avUser m-2 animate__animated animate__flipInY" ></i>
                  }
                  <p className='my-1 text-muted letraPerfil'>{alumno.modalidad === "RE" ? "pre - " + alumno.servicio : alumno.servicio}</p>
                  <p className='my-2 letraPerfil'><b >Nombre: </b> {alumno.nombre}</p>
                  <p className='my-2 letraPerfil'><b>Apellidos: </b> {alumno.apellidos}</p>
                  <p className='my-2 letraPerfil'><b>Turno: </b> {alumno.manana ? "mañana" : ""} {alumno.tarde ? "tarde" : ""}</p>
                  <p className='my-2 letraPerfil'><b>Horarios: </b> {alumno.horarios}</p>
                </div>

                <div className='col-lg-7 col-sm-12 mb-4'>
                  <b className='letraPerfil'>Pagos: </b><br />
                  {
                    alumno.pago === "C"
                      ? <i className="fa-solid fa-circle-check my-2" style={{ "color": "var(--success)", "fontSize": "1.7rem" }}></i> 
                      : <i className="fa-solid fa-triangle-exclamation my-2" style={{ "color": "red", "fontSize": "1.7rem" }}></i>
                  }
                  <br/>
                  <small style={{ "color": alumno.pago === "C" ? "green" : alumno.pago === "P" ? "red" : "red" }}> {alumno.pago === "C" ? "Matemáticas con Jimmy te agradece por haber completado todos tus pagos." : alumno.pago === "P" ? "Matemáticas con Jimmy te informa que aún te falta completar un pago." : "Matemáticas con Jimmy te informa que aún no has cancelado ningún pago."} </small>
                  <div className='progress bg-dark px-0 text-light fs-6' style={{ "height": "25px" }}>
                    <div className="progress-bar" style={{ "width": (barra ? alumno.pago === "C" ? "100%" : alumno.pago === "P" ? "50%" : "0%" : "0%"), "background": (alumno.pago === "C" ? "var(--success)" : "var(--danger)") }} role="progressbar">{alumno.pago === "C" ? "Pago completado!" : ""}</div>
                    {alumno.pago === "C" ? "" : alumno.pago === "P" ? "Falta 1 pago" : "No realizaste ningun pago"}
                  </div>
                  <p className='my-2 letraPerfil'> <b>Fecha de matrícula: </b> {alumno.fechaInicio}</p>
                  <p className='my-2 letraPerfil'> <b>Fin de matrícula: </b> {alumno.fechaFin}</p>
                  <b className='letraPerfil'>Avance total: </b> <br />
                  <small className='text-muted'> Cuando la barra llegue al 100% habra terminado tu acceso a Matemáticas con Jimmy. </small>
                  <div className='progress bg-dark text-light mb-3 fs-6' style={{ "height": "25px" }}>
                    <div className="progress-bar" style={{ "width": (barra ? porcentaje + "%" : "0%"), "background": (porcentaje >= 80 ? "var(--warning)" : "var(--success)"), }} role="progressbar"></div>
                    {porcentaje<100? porcentaje : 100}%
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>


    </div >
  )
}
