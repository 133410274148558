import { AppRouter } from './router/AppRouter';
import "./styles/style.css"

function App() {

  return (

    <AppRouter />
  );
}

export default App;
