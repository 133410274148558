import React from 'react'
import { Field, Formik } from "formik"
import { createUsuarioRequest } from '../../api/usuario.api'
import swal from "sweetalert"

export const InsertAlumno = () => {


  return (
    <div>
      <h3 className='m-3 fst-italic'> Matricular alumno: </h3>

      <Formik
        initialValues={{
          nombre: "",
          apellidos: "",
          pago: "def",
          modalidad: "def",
          servicio: "def",
          fechaInicio: "",
          fechaFin: "",
          telefono: "",
          telefonoApoderado: "",
          correo: "",
          administrador: false,
          contrasena: "",
          horarios: "",
          manana: false,
          tarde: false,
          algebra: false,
          aritmetica: false,
          geometria: false,
          trigonometria: false,
          fisica: false,
          quimica: false,
          rm: false,
          biologia: false,
          comunicado: ""
        }}
        onSubmit={async (values, actions) => {

          try {
            await createUsuarioRequest(values)
            swal({
              title: "Alumno matriculado",
              icon: "success",
              buttons: "OK"
            })
            actions.resetForm()
          } catch (error) {
            console.log(error)
            swal({
              title: "Error",
              icon: "error",
              text: "Los campos nombre, apellidos, modalidad, curso, pago, fechas y contraseña son obligatorios",
              buttons: "OK"
            })
          }
        }}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className='row mx-3'>
              <div className='col-md-3 col-sm-12 my-2'>
                <label className='fw-bold'> Nombre </label>
                <input
                  name="nombre"
                  onChange={handleChange}
                  type="text"
                  className='form-control border border-dark'
                  value={values.nombre}
                />
              </div>

              <div className='col-md-9 col-sm-12 my-2'>
                <label className='fw-bold'> Apellidos </label>
                <input
                  name="apellidos"
                  onChange={handleChange}
                  type="text"
                  className='form-control border border-dark'
                  value={values.apellidos}
                />

              </div>

              <div className='col-md-6 col-sm-12 my-2'>
                <label className='fw-bold'> Modalidad </label>
                <Field as="select" name="modalidad" className="form-select border border-dark">
                  <option value='def' hidden> Escoja modalidad </option>
                  <option value="PRE">Preparación pre - universitaria</option>
                  <option value="RE">Nivelación académica</option>
                </Field>
              </div>

              <div className='col-md-6 col-sm-12 my-2'>
                <label className='fw-bold'> Curso </label>
                {

                  (values.modalidad === "PRE")
                    ?
                    <Field as="select" name="servicio" className="form-select border border-dark">
                      <option value='def' hidden> Escoja servicio </option>
                      <option value="PUCP">Pontificia Universidad Católica del Perú</option>
                      <option value="UNSAAC">Universidad Nacional de San Antonio Abad del Cusco</option>
                      <option value="UAC">Universidad Andina del Cusco</option>
                      <option value="UPCH">Universidad Peruana Cayetano Heredia</option>
                      <option value="UPC">Universidad Peruana de Ciencias Aplicadas</option>
                      <option value="UCSM">Universidad Católica de Santa María</option>
                      <option value="UCSP">Universidad Católica de San Pablo</option>
                      <option value="UPAO">Universidad Privada Antenor Orrego</option>
                      <option value="EOPNP">Escuela de Oficiales de la Policía Nacional del Perú</option>
                      <option value="EMCH">Escuela Militar de Chorrillos</option>
                      <option value="UNI">Universidad Nacional de Ingeniería</option>
                      <option value="UNMSM">Universidad Nacional Mayor de San Marcos</option>
                      <option value="UNFV">Universidad Nacional Federico Villareal</option>
                      <option value="UTEA">Universidad Tecnológica de los Andes</option>
                      <option value="OTRO">Otro</option>
                    </Field>

                    :
                    <Field as="select" name="servicio" className="form-select border border-dark" >
                      <option value='def' hidden> Escoja servicio </option>
                      <option value="6p">pre - 6p</option>
                      <option value="1">pre - 1</option>
                      <option value="2">pre - 2</option>
                      <option value="3">pre - 3</option>
                      <option value="4">pre - 4</option>
                      <option value="5">pre - 5</option>
                    </Field>

                }
              </div>

              <div className='col-md-4 col-sm-12 my-2'>
                <label className='fw-bold'> Fecha de inicio </label>
                <input className='form-control border border-dark' type="date" onChange={handleChange} id="fechaInicio" name="fechaInicio" value={values.fechaInicio} />
              </div>

              <div className='col-md-4 col-sm-12 my-2'>
                <label className='fw-bold'> Fecha fin </label>
                <input className='form-control border border-dark' type="date" onChange={handleChange} id="fechaFin" name="fechaFin" value={values.fechaFin} />
              </div>

              <div className='col-md-4 col-sm-12 my-2'>
                <label className='fw-bold'> Teléfono de alumno </label>
                <input
                  name="telefono"
                  onChange={handleChange}
                  type="text"
                  className='form-control border border-dark'
                  value={values.telefono}
                />

              </div>

              <div className='col-md-4 col-sm-12 my-2'>
                <label className='fw-bold'> Teléfono de apoderado </label>
                <input
                  name="telefonoApoderado"
                  onChange={handleChange}
                  type="text"
                  className='form-control border border-dark'
                  value={values.telefonoApoderado}
                />

              </div>

              <div className='col-md-4 col-sm-12 my-2'>
                <label className='fw-bold'> Correo </label>
                <input
                  name="correo"
                  onChange={handleChange}
                  type="email"
                  className='form-control border border-dark'
                  value={values.correo}
                />
              </div>

              <div className='col-md-4 col-sm-12 my-2'>
                <label className='fw-bold'> Contraseña </label>
                <input
                  name="contrasena"
                  onChange={handleChange}
                  type="text"
                  className='form-control border border-dark'
                  value={values.contrasena}
                />

              </div>

              <div className='col-md-3 col-sm-12 my-2'>
                <label className='fw-bold'> Horarios </label>
                <input
                  name="horarios"
                  onChange={handleChange}
                  type="text"
                  className='form-control border border-dark'
                  value={values.horarios}
                />

              </div>

              <div className='col-md-3 col-sm-12 my-2'>
                <label className='fw-bold'> Pago </label>
                <Field as="select" name="pago" className="form-select border border-dark">
                  <option value='def' hidden> Seleccione pago </option>
                  <option value="C">Pago completado</option>
                  <option value="P">Primer pago cancelado</option>
                  <option value="N">Ningún pago cancelado</option>
                </Field>
              </div>

              <div className='col-md-1 mt-4 d-flex justify-content-center'>
                <h5 className='fw-bold'> Turnos:  </h5>
              </div>
              <div className="form-check form-check-inline pt-2 col-5 col-md-2 mt-4 d-flex justify-content-center">
                <input onChange={handleChange} name="manana" className="btn-check" id="manana" type="checkbox" checked={values.manana} />
                <label className="btn btn-outline-primary fw-bold" htmlFor="manana">Mañana</label>
              </div>
              <div className="form-check form-check-inline pt-2 col-5 col-md-2 mt-4 d-flex justify-content-center">
                <input onChange={handleChange} name="tarde" className="btn-check" id="tarde" type="checkbox" checked={values.tarde} />
                <label className="btn btn-outline-primary fw-bold" htmlFor="tarde">Tarde</label>
              </div>


              <h5 className='my-3'> El alumno tendra acceso a los siguientes cursos:  </h5>
              <div className='d-flex justify-content-center row'>
                <div className="form-check form-check-inline col-5 col-md-2 d-flex justify-content-center">
                  <input onChange={handleChange} name="algebra" className="btn-check" id="algebra" type="checkbox" checked={values.algebra} />
                  <label className="btn btn-outline-primary fw-bold" htmlFor="algebra">Álgebra</label>
                </div>
                <div className="form-check form-check-inline col-5 col-md-2 d-flex justify-content-center">
                  <input onChange={handleChange} name="aritmetica" className="btn-check" id="aritmetica" type="checkbox" checked={values.aritmetica} />
                  <label className="btn btn-outline-primary fw-bold" htmlFor="aritmetica">Aritmética</label>
                </div>
                <div className="form-check form-check-inline col-5  col-md-2 d-flex justify-content-center">
                  <input onChange={handleChange} name="geometria" className="btn-check" id="geometria" type="checkbox" checked={values.geometria} />
                  <label className="btn btn-outline-primary fw-bold" htmlFor="geometria">Geometría</label>
                </div>
                <div className="form-check form-check-inline col-5 col-md-2 d-flex justify-content-center">
                  <input onChange={handleChange} name="trigonometria" className="btn-check" id="trigonometria" type="checkbox" checked={values.trigonometria} />
                  <label className="btn btn-outline-primary fw-bold" htmlFor="trigonometria">Trigonometría</label>
                </div>
              </div>
              <div className='d-flex justify-content-center row mt-2'>
                <div className="form-check form-check-inline col-5 col-md-2 d-flex justify-content-center">
                  <input onChange={handleChange} name="fisica" className="btn-check" id="fisica" type="checkbox" checked={values.fisica} />
                  <label className="btn btn-outline-primary fw-bold" htmlFor="fisica">Física</label>
                </div>
                <div className="form-check form-check-inline col-5 col-md-2 d-flex justify-content-center">
                  <input onChange={handleChange} name="quimica" className="btn-check" id="quimica" type="checkbox" checked={values.quimica} />
                  <label className="btn btn-outline-primary fw-bold" htmlFor="quimica">Química</label>
                </div>
                <div className="form-check form-check-inline col-5 col-md-2 d-flex justify-content-center">
                  <input onChange={handleChange} name="rm" className="btn-check" id="rm" type="checkbox" checked={values.rm} />
                  <label className="btn btn-outline-primary fw-bold" htmlFor="rm">RM</label>
                </div>
                <div className="form-check form-check-inline col-5 col-md-2 d-flex justify-content-center">
                  <input onChange={handleChange} name="biologia" className="btn-check" id="biologia" type="checkbox" checked={values.biologia} />
                  <label className="btn btn-outline-primary fw-bold" htmlFor="biologia">Biología</label>
                </div>
              </div>

              <div className='col-md-12 col-sm-12 my-2'>
                <label className='fw-bold'> Comunicado personal </label>
                <Field as="textarea" name="comunicado" className="form-select border border-dark" onChange={handleChange} value={values.comunicado}>

                </Field>

                {

                  (values.comunicado.length >= 301)
                    ? <p className='text-danger'><i className="fa-solid fa-circle-exclamation"></i> ALERTA, solo puedes escribir hasta 300 caracteres. Te estas excediendo por {values.comunicado.length - 300} caracteres</p>
                    : <p className='text-success'><i className="fa-solid fa-circle-check"></i> Cantidad de caracteres restantes: {300 - values.comunicado.length} </p>
                }
              </div>



              <div className='d-grid gap-2 col-6 mx-auto my-4'>
                <button type='submit' className='btn btn-primary navPrincipal' disabled={isSubmitting}>
                  {isSubmitting ? "Creando ..." : "Crear"}
                </button>
              </div>

            </div>
          </form>
        )}

      </Formik>


    </div>
  )
}
