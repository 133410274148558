import React, { useEffect, useState } from 'react'
import { getExamenesByUsuarioRequest } from '../../api/usuario.api'
import moment from "moment"

export const Notas = () => {

  const [examenes, setExamenes] = useState([])

  useEffect(() => {
    loadExamenes()

  },)


  const loadExamenes = async () => {
    const response = await getExamenesByUsuarioRequest(sessionStorage.getItem("i"))
    setExamenes(response.data)

  }

  return (
    <div>
      <table className="table table-success table-hover table-striped">
        <thead>
          <tr className="text-center">
            <th> fecha </th>
            <th> observaciones </th>
            <th> nota </th>
          </tr>
        </thead>
        <tbody>

          {
            examenes.map((examen) => (
              <tr className="text-center" key={examen.idExamen}>
                <td data-title="fecha de examen:"> {moment(examen.fechaExamen).utc().format("DD/MM/YYYY")} </td>
                <td data-title="observaciones:"> {examen.observaciones} </td>
                <td data-title="nota:"> {examen.nota} </td>
              </tr>
            ))
          }

        </tbody>
      </table>
    </div>
  )
}
