import React from 'react'

export const ComPersonal = ({ comunicado }) => {
    return (
        <div className="border border-warning rounded-3 border-3 my-1 letraComunicado">
            <div className='p-2 '>
                <span><b>Mensaje personal: </b>{comunicado}</span>
            </div>
        </div>
    )
}
