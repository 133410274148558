import React, { useEffect, useState } from 'react'
import { Field, Formik } from "formik"
import { useParams } from 'react-router-dom'
import { createExamenRequest, deleteExamenRequest, getExamenesByUsuarioRequest, getUsuarioRequest } from '../../api/usuario.api'
import moment from "moment"
import swal from "sweetalert"
import { ModalNotas } from './ModalNotas'

export const NotasAction = () => {
    const params = useParams()
    const [examenes, setExamenes] = useState([])
    const [nombre, setNombre] = useState("")
    useEffect(() => {
        loadAlumno()
        loadExamenes()

    })


    const loadExamenes = async () => {
        const response = await getExamenesByUsuarioRequest(params.id)
        setExamenes(response.data)

    }

    const loadAlumno = async () => {
        const response = await getUsuarioRequest(params.id)
        setNombre(response.data.nombre)
    }

    const deleteExamen = (id) => {
        swal({
            title: "Borrar examen",
            text: "Esta seguro que desea eliminar este examen?",
            icon: "warning",
            buttons: ["No", "Si"]
        }).then(async res => {
            if (res) {
                await deleteExamenRequest(id)
                
                swal({ text: "se ha eliminado el examen", icon: "success" })
                loadExamenes()
            }
            else {

            }
        })

    }

    return (
        <div>
            <h3 className='m-3 fst-italic'> Nuevo examen: </h3>

            <Formik
                initialValues={{
                    idUsuario: params.id,
                    fechaExamen: "",
                    nota: 0,
                    observaciones: ""
                }}
                onSubmit={async (values, actions) => {
                    try {
                        await createExamenRequest(values)
                        loadExamenes()
                    
                        swal({
                            title: "Examen registrado",
                            icon: "success",
                            buttons: "OK"
                        })
                        actions.resetForm()
                    } catch (error) {
                        console.log(error)
                        swal({
                            title: "Error",
                            icon: "error",
                            text: "Es obligatorio completar los campos fecha de examen y nota",
                            buttons: "OK"
                        })
                    }
                }}
            >
                {({ handleChange, handleSubmit, setFieldValue, values, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='row mx-3'>
                            <div className='col-md-4 col-sm-12 my-2'>
                                <label className='fw-bold'> Fecha de examen </label>
                                <input className='form-control border border-dark' type="date" onChange={handleChange} id="fechaInicio" name="fechaExamen" value={values.fechaExamen} />
                            </div>
                            <div className='col-md-3 col-sm-12 my-2'>
                                <label className='fw-bold'> Nota </label>
                                <input
                                    name="nota"
                                    onChange={handleChange}
                                    type="number"
                                    className='form-control border border-dark'
                                    value={values.nota}
                                />
                            </div>

                            <div className='col-md-9 col-sm-12 my-2'>
                                <label className='fw-bold'> Obervaciones </label>
                                <Field as="textarea" name="observaciones" className="form-select border border-dark">

                                </Field>
                                {/* <input
                                    name="observaciones"
                                    onChange={handleChange}
                                    type="text"
                                    className='form-control border border-dark'
                                    value={values.observaciones}
                                /> */}

                            </div>

                            <div className='d-grid gap-2 col-6 mx-auto my-4'>
                                <button type='submit' className='btn btn-primary navPrincipal' disabled={isSubmitting}>
                                    {isSubmitting ? "Creando ..." : "Crear"}
                                </button>
                            </div>

                        </div>
                    </form>
                )}

            </Formik>

            <div>
                <h3 className='m-3 fst-italic'> Examenes rendidos por {nombre}: </h3>
                <table className="table table-success table-hover table-striped">
                    <thead>
                        <tr className="text-center">
                            <th> fecha </th>
                            <th> observaciones </th>
                            <th> nota </th>
                        </tr>
                    </thead>
                    <tbody>

                        {

                            examenes.map((examen) => (
                                <tr className="text-center" key={examen.idExamen}>
                                    <td data-title="fecha:"> {moment(examen.fechaExamen).utc().format("DD/MM/YYYY")} </td>
                                    <td data-title="observaciones:"> {examen.observaciones} </td>
                                    <td data-title="nota:"> {examen.nota} </td>
                                    <td className="px-1">
                                        <button type="button" className="btn btn-primary mx-2" data-bs-toggle="modal" data-bs-target={`#idNota${examen.idExamen}`}> <i className="fa-solid fa-clipboard-user"> </i> </button>
                                        <ModalNotas id={`idNota${examen.idExamen}`} ide={examen.idExamen}/>
                                        
                                        <button onClick={() => deleteExamen(examen.idExamen)} className="btn btn-danger"> <i className="fa-solid fa-trash-can "></i> </button>
                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}
