import React, { useContext } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { Material } from './Material'
import { Alumnos } from './Alumnos'
import { InsertAlumno } from './InsertAlumno'
import { NavAdmin } from './NavAdmin'
import { EditAlumno } from './EditAlumno'
import { InsertMaterial } from './InsertMaterial'
import { Asistencia } from './Asistencia'
import { Notas } from './Notas'
import { Ingresantes } from './Ingresantes'
import { NotasAction } from './NotasAction'
import { AsistenciaDetalle } from './AsistenciaDetalle'
import { Comunicados } from './Comunicados'
import { userContext } from '../../router/AppRouter'



export const Admin = () => {
  const {logging, typeU} = useContext(userContext)
  return (
    <>
      <NavAdmin />
      <Routes>
        <Route path="/" element={sessionStorage.getItem("aduth") != null && logging && typeU==="A"? <Alumnos /> : <Navigate to="/aulavirtual" />} />
        <Route path="/alumnos" element={sessionStorage.getItem("aduth") != null && logging  && typeU==="A" ? <Alumnos /> : <Navigate to="/aulavirtual" /> } />
        <Route path="/alumnos/ingresantes" element={sessionStorage.getItem("aduth") != null && logging  && typeU==="A" ? <Ingresantes /> : <Navigate to="/aulavirtual" />} />
        <Route path="/alumnos/insert" element={sessionStorage.getItem("aduth") != null && logging  && typeU==="A" ? <InsertAlumno /> : <Navigate to="/aulavirtual" />} />
        <Route path="/alumnos/update/:id" element={sessionStorage.getItem("aduth") != null && logging  && typeU==="A" ? <EditAlumno /> : <Navigate to="/aulavirtual" />} />
        <Route path="/alumnos/comunicados" element={sessionStorage.getItem("aduth") != null && logging  && typeU==="A" ? <Comunicados /> : <Navigate to="/aulavirtual" />} />
        <Route path="/material" element={sessionStorage.getItem("aduth") != null && logging  && typeU==="A" ? <Material /> : <Navigate to="/aulavirtual" />} />
        <Route path="/material/insert" element={sessionStorage.getItem("aduth") != null && logging  && typeU==="A" ? <InsertMaterial /> : <Navigate to="/aulavirtual" />} />
        <Route path="/asistencia" element={sessionStorage.getItem("aduth") != null && logging  && typeU==="A" ? <Asistencia /> : <Navigate to="/aulavirtual" />} />
        <Route path="/asistencia/detalle/:id" element={sessionStorage.getItem("aduth") != null && logging  && typeU==="A" ? <AsistenciaDetalle /> : <Navigate to="/aulavirtual" />} />
        <Route path="/notas" element={sessionStorage.getItem("aduth") != null && logging  && typeU==="A" ? <Notas /> : <Navigate to="/aulavirtual" />} />
        <Route path="/notas/action/:id" element={sessionStorage.getItem("aduth") != null && logging  && typeU==="A" ? <NotasAction /> : <Navigate to="/aulavirtual" />} />
      </Routes>
    </>

  )
}
