import React, { useEffect, useState } from 'react'
import { getUsuariosTurnoRequest } from '../../api/usuario.api'
import { Link } from "react-router-dom"
import { ModalAsistencia } from './ModalAsistencia'


export const Asistencia = () => {
  const [turnoAlumno, setTurnoAlumno] = useState([])
  const [Turno, setTurno] = useState("manana")
  const [search, setSearch] = useState("")



  useEffect(() => {
    loadAlumnosTurno(Turno)
  },[Turno])

  const loadAlumnosTurno = async (turnoo) => {
    const response = await getUsuariosTurnoRequest(turnoo)
    setTurnoAlumno(response.data)
  }

  const filteredAlumnos = turnoAlumno.filter(
    (alumno) => alumno.nombre.toLowerCase().includes(search.toLowerCase())
      || alumno.apellidos.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <div>
      <div className='mx-4'>
        <h4 className='fw-bold pt-2 fst-italic'> Filtrar por turno: </h4>

        <div className='row justify-content-center p-1'>
          
            <input type="radio" className="btn-check col-5" id="mañana" name="optradio" onClick={() => { loadAlumnosTurno("manana"); setTurno("manana") }} defaultChecked={true}/>
            <label className="btn btn-outline-primary col-5 m-1 border border-primary border-3 fw-bold fs-5" htmlFor="mañana">Turno Mañana</label>

            <input type="radio" className="btn-check col-5" id="tarde" name="optradio" onClick={() => { loadAlumnosTurno("tarde"); setTurno("tarde") }} /> 
            <label className="btn btn-outline-success col-5 m-1 border border-success border-3 fw-bold fs-5" htmlFor="tarde">Turno Tarde</label>
        </div>

        <h5 className='fw-bold fst-italic'> Buscar alumno: </h5>
        <span className='text-muted'> Ingrese el nombre o el apellido del alumno para filtrar. </span>
        <input type="text" placeholder="Busca una alumno..." className="form-control mt-3 mb-2 border border-primary px-4"
          onChange={e => setSearch(e.target.value)}
        />
      </div>


      <table className="table table-success table-hover table-striped">
        <thead>
          <tr className="text-center">
            <th> codigo </th>
            <th> apellido </th>
            <th> nombre </th>
            <th> registro </th>
            <th> detalle </th>
          </tr>
        </thead>
        <tbody>

          {
            filteredAlumnos.map((alumno) => (
              <tr className="text-center" key={alumno.idUsuario}>

                <td data-title="codigo:"> {alumno.codigo} </td>
                <td data-title="apellido:"> {alumno.apellidos} </td>
                <td data-title="nombre:">  {alumno.nombre} </td>
                <td data-title="registro:" className="px-1">
                  <button type="button" className="btn btn-outline-primary mx-2" data-bs-toggle="modal" data-bs-target={`#id${alumno.idUsuario}`}> <i className="fa-solid fa-clipboard-user"> </i> </button>

                  <ModalAsistencia id={`id${alumno.idUsuario}`} ide={alumno.idUsuario}/>
                </td>

                <td data-title="detalle:" className="px-1">
                  <Link to={`/aulavirtual/admin/asistencia/detalle/${alumno.idUsuario}`} className="btn btn-outline-primary mx-2" > <i className="fa-solid fa-circle-info"></i> </Link>
                </td>

              </tr>
            ))
          }
        </tbody>
      </table>

    </div>

  )
}
