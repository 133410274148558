import React, { useContext } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { userContext } from '../../router/AppRouter'
import { Asistencia } from './Asistencia'
import { Material } from './Material'
import { NavAlumno } from './NavAlumno'
import { Notas } from './Notas'
import { Perfil } from './Perfil'

export const Alumno = () => {
  
  const {logging} = useContext(userContext)
  return (
    <>
      <NavAlumno />
      <Routes>
      <Route path="/" element={sessionStorage.getItem("aluth")!=null && logging ? <Material />  : <Navigate to="/aulavirtual"/> } />
      <Route path="/inicio" element={sessionStorage.getItem("aluth")!=null && logging ? <Perfil />  : <Navigate to="/aulavirtual"/> }/>
      <Route path="/material" element={sessionStorage.getItem("aluth")!=null && logging ? <Material />  : <Navigate to="/aulavirtual"/> }/>
      <Route path="/asistencia" element={sessionStorage.getItem("aluth")!=null && logging ? <Asistencia /> : <Navigate to="/aulavirtual"/> } />
      <Route path="/notas" element={sessionStorage.getItem("aluth")!=null && logging ? <Notas /> : <Navigate to="/aulavirtual"/> } />
    </Routes>
    </>
    
  )
}
