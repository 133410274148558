import React from 'react'
import moment from "moment"


export const ComunicadosAlumno = ({ lastComunicado }) => {
    
    return (
        <div className='border border-primary border-3 rounded-3 my-1 p-2 letraComunicado'>
            <div>
                
                    <p className='text-center fw-bold m-0'>Comunicado </p>
                    <p className='m-0'>{lastComunicado.descripcion}</p>
                
                <span className='firma'>  &nbsp;&nbsp;&nbsp;&nbsp;  Jimmy Tapara, {moment(lastComunicado.fechaComunicado).utc().format("DD/MM/YYYY")}</span>
        
            </div>
        </div>



    )
}
