import axios from "axios";

const local = "https://mjback-railway-production.up.railway.app"
// const local = "http://localhost:4000"
export const getLoginRequest = async (codigo, contrasena) => {
    return await axios.get(`${local}/login/${codigo}&${contrasena}`)
}

export const getAdminRequest = async()=> {
    return await axios.get(`${local}/admin`)
}

export const getUsuariosRequest = async () => {
    return await axios.get(`${local}/usuario`)
}

export const getUsuarioRequest = async (id) => {
    return await axios.get(`${local}/usuario/${id}`)
}

export const getUsuariosModalidadRequest = async (mod) => {
    return await axios.get(`${local}/${mod}`)
}

export const getUsuariosTurnoRequest = async (turno) => {
    return await axios.get(`${local}/${turno}`)
}

export const createUsuarioRequest = async (usuario) => {
    return await axios.post(`${local}/usuario`, usuario)
}

export const deleteUsuarioRequest = async (id) => {
    return await axios.delete(`${local}/usuario/${id}`)
}

export const updateUsuarioRequest = async (id, newValues) => {
    return await axios.put(`${local}/usuario/${id}`, newValues)
}

export const updatePasswordRequest = async (newContra) => {
    return await axios.put(`${local}/admin`,newContra)
}

//MATERIAL
export const createMaterialRequest = async (material) => {
    return await axios.post(`${local}/material`, material, {
        headers: { "Content-Type": "multipart/form-data" }})
}

export const getMaterialRequest = async () => {
    return await axios.get(`${local}/material`)
}

export const getMaterialByUsuarioRequest = async (id) => {
    return await axios.get(`${local}/material/${id}`)
}

export const deleteMaterialRequest = async (id, modalidad, servicio) =>{
    return await axios.delete(`${local}/material/${id}&${modalidad}&${servicio}`)
}

// INGRESANTES
export const createIngresanteRequest = async (ingresante) => {
    return await axios.post(`${local}/ingresante`, ingresante, {
        headers: { "Content-Type": "multipart/form-data" }})
}

export const getIngresantesRequest = async () => {
    return await axios.get(`${local}/ingresante`)
}

export const deleteIngresanteRequest = async (id, nombre) => {
    return await axios.delete(`${local}/ingresante/${id}&${nombre}`)
}

//EXAMENES
export const getExamenesByUsuarioRequest = async (id) => {
    return await axios.get(`${local}/examenes/${id}`)
}

export const getExamenbyIdRequest = async (id) => {
    return await axios.get(`${local}/examen/${id}`)
}

export const createExamenRequest = async (examen) => {
    return await axios.post(`${local}/examen`, examen)
}

export const deleteExamenRequest = async (id) => {
    return await axios.delete(`${local}/examen/${id}`)
}

export const updateExamenRequest = async (id, newValues) => {
    return await axios.put(`${local}/examen/${id}`, newValues)
}

// ASISTENCIA   
export const createAsistenciaRequest = async (asistencia) => {
    return await axios.post(`${local}/asistencia`, asistencia)
}

export const deleteAsistenciaRequest = async (id) => {
    return await axios.delete(`${local}/asistencia/${id}`)
}

export const getAsistenciaByUsuarioRequest = async (id) => {
    return await axios.get(`${local}/asistencia/${id}`)
}

// COMUNICADOS
export const getComunicadosRequest = async () => {
    return await axios.get(`${local}/comunicados`)
}

export const deleteComunicadoRequest = async (id) => {
    return await axios.delete(`${local}/comunicado/${id}`)
}

export const createComunicadoRequest = async (comunicado) => {
    return await axios.post(`${local}/comunicado`,comunicado)
}

export const getLastComunicadoRequest = async () => {
    return await axios.get(`${local}/lastcomunicado`)
}