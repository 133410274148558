import React, { useEffect, useState } from 'react'
import { Field, Formik } from "formik"
import swal from "sweetalert"
import { getExamenbyIdRequest, updateExamenRequest } from '../../api/usuario.api'
import moment from "moment"

export const ModalNotas = ({ id, ide }) => {

    const [examen, setExamen] = useState({
        fechaExamen: "",
        observaciones: "",
        nota: 0
    })

    useEffect(() => {
        getExamenById()
    })


    const getExamenById = async () => {
        const response = await getExamenbyIdRequest(ide)

        setExamen({
            fechaExamen: moment(response.data.fechaExamen).utc().format("YYYY-MM-DD"),
            observaciones: response.data.observaciones,
            nota: response.data.nota
        })
    }


    const updateExamen = async (id, newValues) => {
        await updateExamenRequest(id, newValues)


    }

    return (
        <div className="modal fade" id={id} tabIndex="-1" aria-labelledby="ModalNotas" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="ModalNotas">Notas</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">


                        <Formik
                            initialValues={examen}
                            enableReinitialize={true}
                            onSubmit={async (values, actions) => {

                                try {
                                    await updateExamen(ide, values)

                                    swal({
                                        title: "Examen actualizado",
                                        icon: "success",
                                        buttons: "OK"
                                    })
                                    actions.resetForm()
                                } catch (error) {
                                    console.log(error)
                                    swal({
                                        title: "Error",
                                        icon: "error",
                                        text: "Es obligatorio completar todos los campos",
                                        buttons: "OK"
                                    })
                                }
                            }}
                        >
                            {({ handleChange, handleSubmit, setFieldValue, values, isSubmitting }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='row mx-3'>

                                        <div className='col-md-6 col-sm-12 my-2'>
                                            <label className='fw-bold'> Fecha </label>
                                            <input className='form-control border border-dark' type="date" onChange={handleChange} id="fecha" name="fechaExamen" value={values.fechaExamen} />
                                        </div>

                                        <div className='col-md-6 col-sm-12 my-2'>
                                            <label className='fw-bold'> Nota </label>
                                            <input className='form-control border border-dark' type="number" onChange={handleChange} id="fecha" name="nota" value={values.nota} />
                                        </div>

                                        <div className='col-md-12 my-2'>
                                            <label className='fw-bold'> Observaciones </label>
                                            <Field as="textarea" name="observaciones" className="form-select border border-dark" onChange={handleChange} value={values.observaciones}>
                                            </Field>
                                        </div>

                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                            <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                                                {isSubmitting ? "Enviando ..." : "Enviar"}
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            )}

                        </Formik>
                    </div>

                </div>
            </div>
        </div>
    )
}
