import React, { useEffect, useState } from 'react'
import { deleteMaterialRequest, getMaterialRequest } from '../../api/usuario.api'
import { Link } from "react-router-dom"
import swal from "sweetalert"

export const Material = () => {
  const [material, setMaterial] = useState([])
  const [servicio, setServicio] = useState("")

  useEffect(() => {
    loadMaterial()

  }, [])



  const deleteMaterial = (idMaterial, modalidad, servicio) => {
    swal({
      title: "Borrar material",
      text: "Esta seguro que desea eliminar este material?",
      icon: "warning",
      buttons: ["No", "Si"]
    }).then(async res => {
      if (res) {
        await deleteMaterialRequest(idMaterial, modalidad, servicio)
        swal({ text: "se ha eliminado el material", icon: "success" })
        loadMaterial()
      }
      else {

      }
    })

  }


  const loadMaterial = async () => {
    const response = await getMaterialRequest()
    setMaterial(response.data)

  }

  const filteredMaterial = material.filter(
    (mat) => mat.servicio.toLowerCase().includes(servicio.toLowerCase())
  )


  return (

    <div>

      <Link to="/aulavirtual/admin/material/insert" className="btn btn-primary m-3 navPrincipal"> <i className="fa-solid fa-plus"> </i> Agregar material</Link>

      <h4 className='mx-2 mb-3 fst-italic'> Filtrar por servicio: </h4>
      
      <div className="px-4">
      <span className='text-muted'> Ingrese el nombre del curso o el numero de grado de reforzamiento, ejemplo: si busca pre - 3 ingrese 3. </span>
        <input type="text" placeholder="Busca por servicio..." className="form-control mt-3 mb-2 border border-primary px-4"
          onChange={e => setServicio(e.target.value)}
        />
      </div>

      <h4 className='mx-2 mb-3 fst-italic'> Lista de todo el material agregado: </h4>

      <table className="table table-success table-hover table-striped">
        <thead>
          <tr className="text-center">
            <th> modalidad </th>
            <th> servicio </th>
            <th> descripcion </th>
            <th> archivo </th>
          </tr>
        </thead>
        <tbody>

          {

            filteredMaterial.map((mat) => (
              <tr className="text-center" key={mat.idMaterial}>
                <td data-title="modalidad:"> {mat.modalidad} </td>
                <td data-title="servicio:">
                  {(["1", "2", "3", "4", "5"].includes(mat.servicio)) ? "pre - " + mat.servicio : mat.servicio}

                </td>
                <td data-title='descripcion:'> {mat.descripcion} </td>
                <td data-title='archivo:'> <a target="_blank" rel="noopener noreferrer" href={`https://mjback-railway-production.up.railway.app/${mat.idMaterial}-${mat.modalidad}-${mat.servicio}.pdf`} > archivo </a> </td>
                <td>
                  <button onClick={() => deleteMaterial(mat.idMaterial, mat.modalidad, mat.servicio)} className="btn btn-danger"> <i className="fa-solid fa-trash-can"></i> </button>
                </td>
              </tr>
            ))
          }

        </tbody>
      </table>
    </div>

  )
}
