import React from 'react'
import { Field, Formik } from "formik"
import { createAsistenciaRequest } from '../../api/usuario.api'
import swal from "sweetalert"


export const ModalAsistencia = ({ id, ide }) => {

  return (
    <div className="modal fade" id={id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Asistencia</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">


            <Formik
              initialValues={{
                idUsuario: ide,
                fecha: "",
                turno: "",
                estado: "asistio",
                justificacion: ""
              }}
              onSubmit={async (values, actions) => {
                console.log(values)
                try {
                  const response = await createAsistenciaRequest(values)
                  console.log(response)
                  swal({
                    title: "Asistencia registrada",
                    icon: "success",
                    buttons: "OK"
                  })
                  actions.resetForm()
                } catch (error) {
                  console.log(error)
                  swal({
                    title: "Error",
                    icon: "error",
                    text: "Es obligatorio completar los campos turno, fecha y asistencia",
                    buttons: "OK"
                  })
                }
              }}
            >
              {({ handleChange, handleSubmit, setFieldValue, values, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <div className='row mx-3'>
                  <label className="fw-bold">Turno
                      </label>

                    <div className='row d-flex justify-content-center p-1'>
                      <Field type="radio" name="turno" value="mañana" id={`${id}mananamodal`} className="btn-check col-5 "  onChange={handleChange}/>
                      <label className="btn btn-outline-primary col-5 m-1 border border-primary border-3 fw-bold" htmlFor={`${id}mananamodal`}>
                        Mañana
                      </label>
                      <Field type="radio" name="turno" value="tarde" id={`${id}tardemodal`} className="btn-check col-5" onChange={handleChange}/>
                      <label className="btn btn-outline-success col-5 m-1 border border-success border-3 fw-bold" htmlFor={`${id}tardemodal`}>
                        Tarde
                      </label>
                    </div>


                    <div className='col-md-6 col-sm-12 my-2'>
                      <label className='fw-bold'> Fecha </label>
                      <input className='form-control border border-dark' type="date" onChange={handleChange} id="fecha" name="fecha" value={values.fecha} />
                    </div>
                    <div className='col-md-6 col-sm-12 my-2'>
                      <label className='fw-bold'> Asistencia </label>

                      <Field as="select" name="estado" className="form-select border border-dark"
                      >
                        <option value="asistio"> Asistió </option>
                        <option value="tardanza"> Tardanza </option>
                        <option value="falta"> Falta </option>
                      </Field>
                    </div>

                    <div className='col-md-12 my-2'>
                      <label className='fw-bold'> Justificacion </label>
                      <Field as="textarea" name="justificacion" className="form-select border border-dark">

                      </Field>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                      <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                        {isSubmitting ? "Enviando ..." : "Enviar"}
                      </button>
                    </div>

                  </div>
                </form>
              )}

            </Formik>
          </div>

        </div>
      </div>
    </div>
  )
}
