import { Formik } from 'formik'
import React, { useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import { getLoginRequest } from '../../api/usuario.api'
import { NavBar } from '../ui/NavBar'
import { Pie } from "../ui/Pie"
import swal from "sweetalert"
import { userContext } from '../../router/AppRouter'





export const AulaScreen = () => {
  const navigate = useNavigate()
  sessionStorage.clear()
  const {setLogging, setTypeU} = useContext(userContext)
  
  useEffect(() => {
   setLogging(false)
  }, [setLogging])
  
  return (
    
    
      <div className='landing'>
      
        <NavBar />
        <div className='banner'>
          <div className='text-light px-5 py-4 text-center animate__animated animate__fadeIn'>
            <Link to="/" style={{ "textDecoration": "none" }} className="text-light">
              <h1 className='letra1'> Centro de estudio personalizado </h1>
              <h1 className='letra1'> Matemáticas con Jimmy </h1>
            </Link>

            <p className='my-4 fs-5' > Aula virtual </p>
            <ion-icon name="person-circle" color="light" size="large"></ion-icon>
          </div>
        </div>

        <div className='m-3 animate__animated animate__fadeIn text-light'>
          <h3 className='text-center mt-3 fs-3 fw-bold' style={{ "fontFamily": "cambria" }}> Bienvenido a nuestro aula virtual </h3>
          <p className='fs-5'> El aula virtual del centro de estudios personalizado "Matemáticas con Jimmy" contiene material exclusivo para favorecer la preparación académica y un seguimiento a la asistencia y examenes rendidos por el alumno. </p>
          <p className='fs-5'> Únete a nosotros y ten acceso a nuestro contenido. </p>
        </div>

        <div className='animate__animated animate__fadeIn m-0 row justify-content-center text-light' >
          <Formik
            initialValues={{
              codigo: "",
              contrasena: ""
            }}

            onSubmit={async (values, actions) => {
              try {
                const response = await getLoginRequest(values.codigo, values.contrasena)
                response.data.administrador ? sessionStorage.setItem("aduth", 1) :
                  sessionStorage.setItem("aluth", 1)
                setLogging(true)
                sessionStorage.setItem("name", response.data.nombre)
                if (response.data.administrador === 1){
                  setTypeU("A")
                  navigate(`/aulavirtual/admin/alumnos`)
                } 
                else {
                  sessionStorage.setItem("i", response.data.idUsuario)
                  setTypeU("E")
                  navigate(`/aulavirtual/alumno/inicio`)
                }
                actions.resetForm()
              } catch (error) {
                console.log(error)
                swal({
                  title: "Error",
                  icon: "error",
                  text: "Alumno no registrado",
                  buttons: "OK"
                })
              }
            }}
          >
            {
              ({ handleChange, handleSubmit, values, isSubmitting }) => (
                <form className='mb-4 mt-3 col-auto col-md-4 col-sm-8' onSubmit={handleSubmit}>
                  <div className='form-group mb-4 text-center' style={{ "maxWidth": "30rem" }}>
                    <label className='fw-bold fs-5' style={{ "fontFamily": "cambria" }}> Usuario </label>
                    <input
                      name="codigo"
                      value={values.codigo}
                      onChange={handleChange}
                      type="text"
                      placeholder='Ingresa tu usuario'
                      className='form-control border border-dark'

                    />
                  </div>

                  <div className='form-group text-center' style={{ "maxWidth": "30rem" }}>
                    <label className='fw-bold fs-5' style={{ "fontFamily": "cambria" }}> Contraseña </label>
                    <input
                      name="contrasena"
                      value={values.contrasena}
                      onChange={handleChange}
                      type="password"
                      placeholder='Ingresa tu contraseña'
                      className='form-control border border-dark '

                    />
                  </div>

                  <div className='d-flex justify-content-center'>
                    <button type='submit' className='btn btn-primary my-3 colorprim' disabled={isSubmitting}> {isSubmitting ? "Entrando ..." : "Iniciar Sesión"} </button>
                  </div>
                </form>
              )
            }


          </Formik>

        </div>
        <Pie />
      </div>
   



  )
}

