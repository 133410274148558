import React, { useContext, useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { NavBar } from '../ui/NavBar';
import { Pie } from "../ui/Pie"

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import { getIngresantesRequest } from '../../api/usuario.api';
import { Sphere } from '../ui/Sphere';
import { userContext } from '../../router/AppRouter';

export const HomeScreen = () => {
  const [ingresantes, setIngresantes] = useState([])

  useEffect(() => {
    getIngresantes()
  }, [])

  const getIngresantes = async () => {
    const response = await getIngresantesRequest()
    setIngresantes(response.data)
  }

  sessionStorage.clear()

  const { setLogging } = useContext(userContext)

  useEffect(() => {
    setLogging(false)
  }, [setLogging])

  return (

    <div className='landing'>


      <NavBar />
      <div className='banner img-fluid'>
        <div className='text-light px-5 py-4 text-center animate__animated animate__fadeIn'>
          <Link to="/" style={{ "textDecoration": "none" }} className="text-light">
            <h1 className='letra1'> Centro de estudio personalizado </h1>
            <h1 className='letra1'> Matemáticas con Jimmy </h1>
          </Link>

          <p className='my-4 fs-5' > Home </p>
          <ion-icon name="home" color="light" size="large"></ion-icon>
        </div>
      </div>

      <div className='container'>
        <div className='row  containerimg p-4 my-2' >
          <div data-aos="flip-left" className='col-md-5 col-sm-12 mx-2  border border-light text-center text-light align-self-center py-4 rounded-circle ' style={{ "fontFamily": "cursive" }}>
            <h4 >¡Inténtalo y lo <p className='fs-1'> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; lograrás!,</p></h4>
            <h4 className='my-1'>
              nunca dudes que puedes <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; alcanzar tus <p className='fs-1' > &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sueños... </p>
            </h4>
          </div>

          <div className='align-self-center px-2 text-light m-2 col-md-6 col-sm-12' data-aos="fade-top" data-aos-easing="linear"
            data-aos-duration="1000">

            <h4 className='fs-3 text-center'>¿Quiénes somos?</h4>
            <p className='fs-5 bg-dark p-3'>

              El centro de estudio personalizado "Matemáticas con Jimmy" fue creado con la idea de brindar nivelación académica y formar estudiantes universitarios trabajando en grupos reducidos y una enseñanza personalizada a cada alumno. <br />
              <span style={{ "fontFamily": "cursive" }}> Una nueva forma de aprender, una nueva forma de enseñar. ¿Qué esperas para unirte a nosotros?</span>

            </p>
            <div className='text-center'>
              <Link to={"/info"} className="btn btn-info text-light px-4 py-2 fs-5 letra1 buttonInfo"> + Info </Link>
            </div>
          </div>
        </div>
      </div>



      <div className='container'>
        <div className='row text-light'>
          <div className='col-lg-6 col-sm-12 text-center p-5 fs-5'>
            <p style={{ "fontFamily": "cursive" }}>
              Pretendemos marcar la diferencia enseñando al alumno desde cero, brindándole material físico y virtual, con exámenes de años anteriores dependiendo de la universidad que desea postular o el curso que quiere reforzar. Además, mediante nuestra aula virtual, el alumno siempre estará informado de sus notas en nuestros simulacros de examen, su asistencia y el material exclusivo de nuestro centro de estudios.<br /><br />
            </p>
          </div>
          <div className='col-lg-6 col-sm-12 d-flex justify-content-center'>
            <Sphere />
          </div>
        </div>
      </div>


      <div>
        <div>
          <h4 className='text-center text-light pt-5 pb-3 mx-2 fs-3 fw-bold' style={{ "fontFamily": "cambria" }}> ¡Orgullosos de nuestros ingresantes! </h4>

        </div>

        <div className='animate__animated animate__fadeIn ' >
          <Swiper

            className='px-3 pb-4 swiper'
            slidesPerView={1}
            spaceBetween={5}
            modules={[Pagination, Autoplay]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false
            }}
            pagination={{ clickable: true }}
            breakpoints={{

              768: {
                slidesPerView: 1
              },
              1024: {
                slidesPerView: 2
              },
            }}
          >
            {
              ingresantes.map((ingresante) => (
                <SwiperSlide key={ingresante.idIngresante}>
                  <div className='card p-3 carding text-light ' >
                    <div className='row g-0'>
                      <div className='col-md-7 col-sm-11'>
                        <div className='card-body'>
                          <p className='fs-5 text-center card-text' style={{ "fontFamily": "cambria" }}> {ingresante.carrera} </p>
                          <p className='fs-4 text-center fw-bold card-text' style={{ "fontFamily": "cambria" }}> {ingresante.nombre} </p>
                          <p className='fs-5 fst-italic text-center card-text'> {ingresante.universidad} </p>
                          <p className='text-center' style={{ "fontFamily": "cambria" }}> {ingresante.periodo} </p>

                        </div>
                      </div>

                      <div className='col-md-5 col-sm-11 pictures'>
                        <img src={`https://mjback-railway-production.up.railway.app/${ingresante.idIngresante}-${ingresante.nombre}.jpg`} alt="ingresante" className='img-fluid rounded-start m-2 card-img icon2' />
                      </div>

                    </div>

                  </div>
                </SwiperSlide>
              ))
            }

          </Swiper>
          <div className='pagination d-flex justify-content-center my-4'> </div>
        </div>
      </div>








      <h5 className='text-center mt-5 px-2 text-light'> Nos ubicamos en urbanización Santa Rosa, pasaje Los Geranios 282, (frente a la puerta principal del colegio Santa Ana), Cusco, Perú. </h5>

      <div className='mx-5 my-4'>
        <iframe className='col-12' title="ubicacion" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d969.7769561641616!2d-71.94929681196379!3d-13.528973895511102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x916e7f56fec084f3%3A0x61fadd73903cce51!2sGordon%20Magne%20282%2C%20Cusco%2008002!5e0!3m2!1ses!2spe!4v1668143306418!5m2!1ses!2spe" height="300rem" loading="lazy" ></iframe>
      </div>
      <Pie />



    </div>
  )
}
