import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { deleteUsuarioRequest, getUsuariosRequest } from "../../api/usuario.api"
import swal from "sweetalert"
import moment from "moment"

export const Alumnos = () => {
  const [alumnos, setAlumnos] = useState([])
  const [search, setSearch] = useState("")

  useEffect(() => {
    loadAlumnos()
  }, [])

  const filteredAlumnos = alumnos.filter(
    (alumno) => alumno.nombre.toLowerCase().includes(search.toLowerCase())
      || alumno.apellidos.toLowerCase().includes(search.toLowerCase())
  )

  const deleteAlumno = (id) => {
    swal({
      title: "Borrar alumno",
      text: "Esta seguro que desea eliminar este alumno?",
      icon: "warning",
      buttons: ["No", "Si"]
    }).then(async res => {
      if (res) {
        await deleteUsuarioRequest(id)
        swal({ text: "se ha eliminado el alumno", icon: "success" })
        loadAlumnos()
      }
      else {

      }
    })

  }

  const loadAlumnos = async () => {
    const response = await getUsuariosRequest()
    setAlumnos(response.data)
  }


  return (
    <div>
    <div className="container">
    <div className="d-flex row justify-content-between">
        <Link to="/aulavirtual/admin/alumnos/insert" className="col-lg-3 col-sm-5 col-4 btn btn-primary m-3 navPrincipal"> <i className="fa-solid fa-plus"> </i> Agregar alumno</Link>
        <Link to="/aulavirtual/admin/alumnos/comunicados" className="col-lg-3 col-sm-5 col-4 btn btn-primary m-3 navPrincipal"> <i className="fa-solid fa-plus"> </i> Agregar comunicado</Link>
        <Link to="/aulavirtual/admin/alumnos/ingresantes" className="col-lg-3 col-sm-5 col-4 btn btn-primary m-3 navPrincipal"> <i className="fa-solid fa-plus"> </i> Agregar ingresante</Link>
      </div>

    </div>
      
      <h5 className='fw-bold pt-2 fst-italic mx-3'> Buscar alumno: </h5>
      <div className="px-4">
        <span className='text-muted'> Ingrese el nombre o el apellido del alumno para filtrar. </span>
        <input type="text" placeholder="Busca una alumno..." className="form-control mt-3 mb-2 border border-primary px-4"
          onChange={e => setSearch(e.target.value)}
        />
      </div>

      <h4 className='mx-2 mb-3 fst-italic'> Lista de alumnos matriculados: </h4>
      <span className="m-3"> Cantidad de alumnos matriculados:<b> {alumnos.length}</b> </span>
      <table className="mt-2 table table-primary table-hover table-striped">
        <thead>
          <tr className="text-center">
            <th> Código </th>
            <th> Nombre </th>
            <th> Apellidos </th>
            <th> Modalidad </th>
            <th> Turno </th>
            <th> Servicio </th>
            <th> TelAlumno </th>
            <th> TelApoderado </th>
            <th> Inicio </th>
            <th> Fin </th>

          </tr>
        </thead>
        <tbody>

          {
            filteredAlumnos.map((alumno) => (
              <tr className="text-center" key={alumno.idUsuario}>
                <td data-title="Código:"> {alumno.codigo} </td>
                <td data-title="Nombre:"> {alumno.nombre} </td>
                <td data-title="Apellidos:"> {alumno.apellidos} </td>
                <td data-title="Modalidad:"> {alumno.modalidad} </td>
                <td data-title="Turno:"> {alumno.manana? "mañana":""}{alumno.tarde?" ":""}{alumno.tarde?"tarde":""} </td>
                <td data-title="Servicio:"> {alumno.servicio} </td>
                <td data-title="TelAlumno:"> {alumno.telefono} </td>
                <td data-title="TelApoderado:"> {alumno.telefonoApoderado} </td>
                <td data-title="Inicio:"> {moment(alumno.fechaInicio).utc().format("DD/MM/YYYY")} </td>
                <td data-title="Fin:"> {moment(alumno.fechaFin).utc().format("DD/MM/YYYY")} </td>

                <td className="px-1">
                  <Link to={`/aulavirtual/admin/alumnos/update/${alumno.idUsuario}`} className="btn btn-info mx-2" > <i className="fa-solid fa-pen-to-square"></i> </Link>
                  <button onClick={() => deleteAlumno(alumno.idUsuario)} className="btn btn-danger"> <i className="fa-solid fa-trash-can "></i> </button>

                </td>
              </tr>
            ))
          }

        </tbody>
      </table>
    </div>
  )
}
