import React, { useEffect, useState } from 'react'
import { createComunicadoRequest, deleteComunicadoRequest, getComunicadosRequest } from '../../api/usuario.api'
import swal from "sweetalert"
import { Field, Formik } from "formik"
import moment from "moment"

export const Comunicados = () => {
    const [comunicados, setComunicados] = useState([])
    useEffect(() => {
        loadComunicados()
    }, [])

    const loadComunicados = async () => {
        const response = await getComunicadosRequest()
        setComunicados(response.data)
    }

    const deleteComunicado = (id) => {
        swal({
            title: "Borrar comunicado",
            text: "Esta seguro que desea eliminar este comunicado?",
            icon: "warning",
            buttons: ["No", "Si"]
        }).then(async res => {
            if (res) {
                 await deleteComunicadoRequest(id)
    
                swal({ text: "se ha eliminado el comunicado", icon: "success" })
                loadComunicados()
            }
            else {

            }
        })

    }

    return (
        <div>

            <h3 className='m-3 fst-italic'> Nuevo comunicado: </h3>

            <Formik
                initialValues={{
                    descripcion: "",
                    fechaComunicado: ""
                }}
                onSubmit={async (values, actions) => {
                    
                    try {
                        await createComunicadoRequest(values)
                        loadComunicados()
                        
                        swal({
                            title: "Comunicado publicado",
                            icon: "success",
                            buttons: "OK"
                        })
                        actions.resetForm()
                    } catch (error) {
                        console.log(error)
                        swal({
                            title: "Error",
                            icon: "error",
                            text: "Es obligatorio completar todos los campos",
                            buttons: "OK"
                        })
                    }
                }}
            >
                {({ handleChange, handleSubmit, setFieldValue, values, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='row mx-3'>
                            <div className='col-md-4 col-sm-12 my-2'>
                                <label className='fw-bold'> Fecha de comunicado </label>
                                <input className='form-control border border-dark' type="date" onChange={handleChange} id="fechaInicio" name="fechaComunicado" value={values.fechaComunicado} />
                            </div>

                            <div className='col-md-9 col-sm-12 my-2'>
                                <label className='fw-bold'> Comunicado </label>
                                <Field as="textarea" name="descripcion" className="form-select border border-dark">

                                </Field>
                                {
                                    
                                    (values.descripcion.length>=401)
                                    ? <p className='text-danger'><i className="fa-solid fa-circle-exclamation"></i> ALERTA, solo puedes escribir hasta 400 caracteres. Te estas excediendo por {values.descripcion.length-400} caracteres</p> 
                                    : <p className='text-success'><i class="fa-solid fa-circle-check"></i> Cantidad de caracteres restantes: {400 - values.descripcion.length} </p>
                                }
                                
                            </div>

                            <div className='d-grid gap-2 col-6 mx-auto my-4'>
                                <button type='submit' className='btn btn-primary navPrincipal' disabled={isSubmitting}>
                                    {isSubmitting ? "Creando ..." : "Crear"}
                                </button>
                            </div>

                        </div>
                    </form>
                )}

            </Formik>

            <h3 className='m-3 fst-italic'> Mis comunicados: </h3>
            <table className="table table-success table-hover table-striped ">
                <thead>
                    <tr className="text-center">
                        <th> Fecha </th>
                        <th> descripción </th>
                    </tr>
                </thead>
                <tbody>

                    {
                        comunicados.map((com) => (
                            <tr className="text-center" key={com.idComunicado}>
                                <td data-title="fecha:" className='px-5'> {moment(com.fechaComunicado).utc().format("DD/MM/YYYY")} </td>
                                <td data-title="descripción:" className="px-4"> {com.descripcion} </td>
                                <td className="px-4">
                                    <button onClick={() => deleteComunicado(com.idComunicado)} className="btn btn-danger"> <i className="fa-solid fa-trash-can "></i> </button>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </div>
    )
}
