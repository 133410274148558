import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

export const NavBar = () => {
    const [navbar, setNavbar] = useState(false)

    const changeBackground = () => {
       
        if (window.scrollY >= 100) {
            setNavbar(true)
        }
        else {
            setNavbar(false)
        }
    }


    window.addEventListener("scroll", changeBackground)

    return (
        <nav className={'navbar navbar-dark navbar-expand-lg fixed-top ' + (navbar || window.screen.width<1000 ? "colorprim" : "")} >
            <button
                className='navbar-toggler'
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#menu"
                aria-controls="navbarSupportedContent"
                aria-expanded="true"
                aria-label="Toggle navigation"
            >
                <span className='navbar-toggler-icon'></span>

            </button>
            <div className='px-5 navbar-brand row collapse navbar-collapse' id="menu">
                <div className='fs-6 col-md-5 col-sm-12'>
                    <span>
                        <ion-icon name="call" color="light"></ion-icon>
                        <span className='mx-2'>
                            941 018 206
                        </span>
                    </span>

                </div>

                <div className='fs-6 mx-2 col-md-5 col-sm-12'>
                    <span>
                        <ion-icon name="mail" color="light"></ion-icon>
                        <span className='mx-2'>
                            jimmytapara@gmail.com
                        </span>
                    </span>

                </div>


            </div>


            <div className='collapse navbar-collapse justify-content-end' id="menu">
                <ul className='navbar-nav'>
                    <li className='nav-item px-5'>
                        <NavLink
                            className={({ isActive }) => "nav-item nav-link " + (isActive ? "active" : "")}
                            to="/info"
                        >
                            Información
                        </NavLink>
                    </li>
                    <li className='nav-item px-5'>
                        <NavLink
                            className={({ isActive }) => "nav-item nav-link " + (isActive ? "active" : "")}
                            to="/aulavirtual"
                        >
                            Aula Virtual
                        </NavLink>
                    </li>
                </ul>
            </div>

        </nav>
    )
}
