import React from 'react'



export const Modalpdf = ({ id, archivo }) => {
    
    return (
        <div  className="modal fade" id={id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div  className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Material</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className='containerPDF'>
                        <div className='bg-dark' style={{ "width": "100%", "height": "55px", "position": "absolute" }}></div>
                        <iframe title='titulo' src={archivo} style={{ height: "35rem", "width": "100%" }}></iframe>

                    </div>
                </div>
                <div>





                </div>
            </div>
        </div>
    )
}
