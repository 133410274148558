import React from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import swal from "sweetalert"


export const NavAlumno = () => {

    const navigate = useNavigate()

    const onLogout = () => {
        swal({
            title: "Cerrar sesión",
            text: "¿Esta seguro que desea cerrar sesión?",
            icon: "warning",
            buttons: ["No", "Si"]
        }).then(async res => {
            if (res) {

                navigate("/aulavirtual")
            }
            else {

            }
        })
    }


    return (
        <>
            <nav className="navbar navbar-expand-md navbar-dark sticky-top colorprim">
                <Link
                    className='navbar-brand mx-3'
                    to="/aulavirtual/alumno/inicio"
                >
                    Alumno
                </Link>

                <button
                    className='navbar-toggler'
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#menuAlumno"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="true"
                    aria-label="Toggle navigation"
                >
                    <span className='navbar-toggler-icon'></span>

                </button>

                <div className='collapse navbar-collapse' id="menuAlumno">
                    <ul className='navbar-nav text-center'>
                        <li className='nav-item mx-4'>
                            <NavLink
                                className={({ isActive }) => "nav-item nav-link " + (isActive ? "active" : "")}
                                to="/aulavirtual/alumno/material"
                            >
                                Material
                            </NavLink>
                        </li>

                        <li className='nav-item mx-4'>
                            <NavLink
                                className={({ isActive }) => "nav-item nav-link " + (isActive ? "active" : "")}
                                to="/aulavirtual/alumno/asistencia"
                            >
                                Asistencia
                            </NavLink>
                        </li>

                        <li className='nav-item mx-4'>
                            <NavLink
                                className={({ isActive }) => "nav-item nav-link " + (isActive ? "active" : "")}
                                to="/aulavirtual/alumno/notas"
                            >
                                Notas
                            </NavLink>
                        </li>
                    </ul>
                </div>

                <div id="menuAlumno" className='navbar-collapse collapse justify-content-end'>
                    <ul className='navbar-nav px-2'>
                        <span className='nav-item align-self-center text-light'>
                            {sessionStorage.getItem("name")}
                        </span>
                        <button onClick={() => onLogout()} className='nav-item btn mx-3 btn-danger'>
                            Logout
                        </button>
                    </ul>
                </div>
            </nav>
        </>
    )
}
