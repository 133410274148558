import React, { useState } from 'react'
import { Routes, Route, HashRouter } from "react-router-dom"
import { Admin } from '../components/admin/Admin'
import { Alumno } from '../components/alumno/Alumno'
import { AulaScreen } from '../components/aula/AulaScreen'
import { ErrorScreen } from '../components/error/ErrorScreen'
import { HomeScreen } from '../components/home/HomeScreen'
import { InfoScreen } from '../components/info/InfoScreen'

export const userContext = React.createContext()

export const AppRouter = () => {

  const [logging, setLogging] = useState(false)
  const [typeU, setTypeU ] = useState("")
  return (

    <HashRouter>
      <userContext.Provider value={{ logging, setLogging, typeU, setTypeU }}>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path='/info' element={<InfoScreen />} />

          <Route path="/aulavirtual" element={<AulaScreen />} />
          <Route path="/aulavirtual/admin/*" element={<Admin />} />
          <Route path="/aulavirtual/alumno/*" element={<Alumno />} />

          <Route path="*" element={<ErrorScreen />} />
        </Routes>
      </userContext.Provider>
    </HashRouter>



  )
}
