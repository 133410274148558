import React, { useEffect } from 'react'
import TagCloud from 'TagCloud'



export const Sphere = () => {

  useEffect(() => {

    const container = ".tagcloud"
    const texts = ["Álgebra", "Aritmética", "UNSAAC", "Geometría", "UAC", "PUCP", "Física", "UPAO", "Química", "Razonamiento\nMatemático", "UPC", "Trigonometría", "UPCH", "UCSM"]

    const options = {
      radius: 190,
      maxSpeed: "normal",
      initSpeed: "normal",
      keep: true
    }
    TagCloud(container, texts, options)

  }, [])

  return (
    <div className='containersphere px-2 text-center'>
      <span className='tagcloud'></span>
    </div>
  )
}
