import React from 'react'
import { Field, Formik } from "formik"
import { createMaterialRequest } from '../../api/usuario.api'
import swal from "sweetalert"

export const InsertMaterial = () => {
    return (
        <div>
            <h3 className='m-3 fst-italic'> Agregar material: </h3>
            <Formik
                initialValues={{
                    modalidad: "def",
                    servicio: "def",
                    descripcion: "",
                    archivo: ""
                }}
                onSubmit={async (values, actions) => {
                    console.log(values)
                    try {
                        const formData = new FormData()
                        formData.append("modalidad", values.modalidad)
                        formData.append("servicio", values.servicio)
                        formData.append("archivo", values.archivo)
                        formData.append("descripcion", values.descripcion)
                        await createMaterialRequest(formData)
                        swal({
                            title: "Material agregado",
                            icon: "success",
                            buttons: "OK"
                        })
                        actions.resetForm()
                    } catch (error) {
                        console.log(error)
                        swal({
                            title: "Error",
                            icon: "error",
                            text: "Es obligatorio conpletar todos los campos",
                            buttons: "OK"
                        })
                    }
                }}
            >
                {({ handleChange, handleSubmit, setFieldValue, values, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='row mx-3'>

                            <div className='col-md-6 col-sm-12 my-2'>
                                <label className='fw-bold'> Modalidad </label>

                                <Field as="select" name="modalidad" className="form-select border border-dark"
                                >
                                    <option value='def' hidden> Escoja modalidad </option>
                                    <option value="PRE">Preparación pre - universitaria</option>
                                    <option value="RE">Nivelación académica</option>
                                </Field>
                            </div>

                            <div className='col-md-6 col-sm-12 my-2'>
                                <label className='fw-bold'> Servicio </label>
                                {
                                    (values.modalidad === "PRE")
                                        ?
                                        <Field as="select" name="servicio" className="form-select border border-dark">
                                            <option value='def' hidden> Escoja servicio </option>
                                            <option value="algebra">Álgebra</option>
                                            <option value="aritmetica">Aritmética</option>
                                            <option value="geometria">Geometría</option>
                                            <option value="trigonometria">Trigonometría</option>
                                            <option value="fisica">Física</option>
                                            <option value="quimica">Química</option>
                                            <option value="rm">Rmto Matemático</option>
                                            <option value="biologia">Biología</option>
                                        </Field>


                                        :
                                        <Field as="select" name="servicio" className="form-select border border-dark">
                                            <option value='def' hidden> Escoja servicio </option>
                                            <option value="6p">pre - 6p</option>
                                            <option value="1">pre - 1</option>
                                            <option value="2">pre - 2</option>
                                            <option value="3">pre - 3</option>
                                            <option value="4">pre - 4</option>
                                            <option value="5">pre - 5</option>
                                        </Field>

                                }
                            </div>

                            <div className='col-md-4 col-sm-12 my-2'>
                                <label className='fw-bold'> Descripcion </label>
                                <input
                                    name="descripcion"
                                    onChange={handleChange}
                                    type="text"
                                    className='form-control border border-dark'
                                    value={values.descripcion}
                                />
                            </div>


                            <div className='col-md-4 col-sm-12 my-2'>
                                <label className='fw-bold'> Archivo </label>
                                <input
                                    onChange={(event) => {
                                        setFieldValue("archivo", event.target.files[0])
                                    }}
                                    className='form-control border border-dark'
                                    type="file"
                                    accept='.pdf'
                                />
                            </div>

                            <div className='d-grid gap-2 col-6 mx-auto my-4'>
                                <button type='submit' className='btn btn-primary navPrincipal' disabled={isSubmitting}>
                                    {isSubmitting ? "Creando ..." : "Crear"}
                                </button>
                            </div>

                        </div>
                    </form>
                )}

            </Formik>


        </div>
    )
}

