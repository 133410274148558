import React, { useEffect, useState } from 'react'
import { Formik } from "formik"
import { createIngresanteRequest, deleteIngresanteRequest, getIngresantesRequest } from '../../api/usuario.api'
import swal from "sweetalert"

export const Ingresantes = () => {

    const [ingresantes, setIngresantes] = useState([])

    useEffect(() => {
        loadIngresantes()
    }, [])


    const loadIngresantes = async () => {
        const response = await getIngresantesRequest()
        setIngresantes(response.data)
    }

    const deleteIngresante = (id, nombre) => {
        swal({
            title: "Borrar ingresante",
            text: "Esta seguro que desea eliminar este ingresante?",
            icon: "warning",
            buttons: ["No", "Si"]
        }).then(async res => {
            if (res) {
                const response = await deleteIngresanteRequest(id, nombre)
                console.log(response)
                swal({ text: "se ha eliminado el ingresante", icon: "success" })
                loadIngresantes()
            }
            else {

            }
        })

    }

    return (
        <div>
            <h3 className='m-3 fst-italic'> Agregar ingresante: </h3>
            <Formik
                initialValues={{
                    nombre: "",
                    carrera: "",
                    universidad: "",
                    periodo: "",
                    imagen: ""
                }}
                onSubmit={async (values, actions) => {
                    console.log(values)
                    try {
                        const formData = new FormData()
                        formData.append("nombre", values.nombre)
                        formData.append("carrera", values.carrera)
                        formData.append("universidad", values.universidad)
                        formData.append("periodo", values.periodo)
                        formData.append("imagen", values.imagen)
                        const response = await createIngresanteRequest(formData)
                        console.log(response)
                        swal({
                            title: "Ingresante agregado",
                            icon: "success",
                            buttons: "OK"
                        })
                        actions.resetForm()
                        loadIngresantes()
                    } catch (error) {
                        console.log(error)
                        swal({
                            title: "Error",
                            icon: "error",
                            text: "Es obligatorio llenar los campos de nombre, carrera, universidad y periodo",
                            buttons: "OK"
                        })
                    }
                }}
            >
                {({ handleChange, handleSubmit, setFieldValue, values, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='row mx-3'>

                            <div className='col-md-6 col-sm-12 my-2'>
                                <label className='fw-bold'> Nombre </label>
                                <input
                                    name="nombre"
                                    onChange={handleChange}
                                    type="text"
                                    className='form-control border border-dark'
                                    value={values.nombre}
                                />
                            </div>

                            <div className='col-md-6 col-sm-12 my-2'>
                                <label className='fw-bold'> Carrera </label>
                                <input
                                    name="carrera"
                                    onChange={handleChange}
                                    type="text"
                                    className='form-control border border-dark'
                                    value={values.carrera}
                                />
                            </div>

                            <div className='col-md-4 col-sm-12 my-2'>
                                <label className='fw-bold'> Universidad </label>
                                <input
                                    name="universidad"
                                    onChange={handleChange}
                                    type="text"
                                    className='form-control border border-dark'
                                    value={values.universidad}
                                />
                            </div>

                            <div className='col-md-4 col-sm-12 my-2'>
                                <label className='fw-bold'> Periodo </label>
                                <input
                                    name="periodo"
                                    onChange={handleChange}
                                    type="text"
                                    className='form-control border border-dark'
                                    value={values.periodo}
                                />
                            </div>


                            <div className='col-md-4 col-sm-12 my-2'>
                                <label className='fw-bold'> Imagen </label>
                                <input
                                    onChange={(event) => {
                                        setFieldValue("imagen", event.target.files[0])
                                    }}
                                    className='form-control border border-dark'
                                    type="file"
                                    accept='.jpg, .jpeg, .png'
                                />
                            </div>

                            <div className='d-grid gap-2 col-6 mx-auto my-4'>
                                <button type='submit' className='btn btn-primary navPrincipal' disabled={isSubmitting}>
                                    {isSubmitting ? "Creando ..." : "Crear"}
                                </button>
                            </div>

                        </div>
                    </form>
                )}

            </Formik>

            <h4 className='mx-3'> Mis ingresantes:  </h4>
            
            <div className='row animate__animated animate__fadeIn mx-2 my-3'>
                {
                    ingresantes.map((ingresante) => (
                        <div key={ingresante.idIngresante} className='card col-lg-4 col-md-5 col-sm-12 border border-dark p-3'>

                            <div className='row'>
                                <div className='card-body col-md-6 col-12'>
                                    <p className='card-text p-2'>
                                        <small>
                                            <b>Nombre:</b>  {ingresante.nombre} <br />
                                            <b>Carrera:</b>  {ingresante.carrera} <br />
                                            <b>Universidad:</b>  {ingresante.universidad} <br />
                                            <b>Periodo:</b>  {ingresante.periodo} <br />
                                            <button onClick={() => deleteIngresante(ingresante.idIngresante, ingresante.nombre)} className="btn btn-danger"> <i className="fa-solid fa-square-minus"></i> </button>
                                        </small>
                                    </p>
                                </div>
                                <div className='col-md-6 col-12'>
                                    <img src={`https://mjback-railway-production.up.railway.app/${ingresante.idIngresante}-${ingresante.nombre}.jpg`} alt="ingresante" className='card-img icon2' />
                                </div>

                            </div>

                        </div>
                    ))
                }
            </div>
            
            
        </div>
    )
}
