import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getUsuariosRequest } from "../../api/usuario.api"


export const Notas = () => {
  const [alumnos, setAlumnos] = useState([])
  const [search, setSearch] = useState("")

  useEffect(() => {
    loadAlumnos()
  }, [])


  const loadAlumnos = async () => {
    const response = await getUsuariosRequest()
    setAlumnos(response.data)
  }
  const filteredAlumnos = alumnos.filter(
    (alumno) => alumno.nombre.toLowerCase().includes(search.toLowerCase())
      || alumno.apellidos.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <div>
    <h5 className='fw-bold pt-2 fst-italic mx-3'> Buscar alumno: </h5>
      <div className="px-4">
      <span className='text-muted'> Ingrese el nombre o el apellido del alumno para filtrar. </span>
        <input type="text" placeholder="Busca una alumno..." className="form-control mt-3 mb-2 border border-primary px-4"
          onChange={e => setSearch(e.target.value)}
        />
      </div>


      <table className="table table-success table-hover table-striped">
        <thead>
          <tr className="text-center">
            <th> codigo </th>
            <th> nombre </th>
            <th> apellidos </th>
            <th> notas </th>
          </tr>
        </thead>
        <tbody>

          {
            filteredAlumnos.map((alumno) => (
              <tr className="text-center" key={alumno.idUsuario}>
                <td data-title="codigo:"> {alumno.codigo} </td>
                <td data-title="nombre:"> {alumno.nombre} </td>
                <td data-title="apellidos:"> {alumno.apellidos} </td>
                <td data-title="notas:" className="px-1">
                
                  <Link to={`/aulavirtual/admin/notas/action/${alumno.idUsuario}`} className="btn btn-outline-primary mx-2" > <i className="fa-solid fa-clipboard"></i> </Link>

                </td>
              </tr>
            ))
          }

        </tbody>
      </table>
    </div>
  )
}
