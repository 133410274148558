import React, { useEffect, useState } from 'react'
import { getAsistenciaByUsuarioRequest } from '../../api/usuario.api'
import moment from "moment"

export const Asistencia = () => {

  const [asistencia, setAsistencia] = useState([])

  useEffect(() => {
    loadExamenes()

  },)


  const loadExamenes = async () => {
    const response = await getAsistenciaByUsuarioRequest(sessionStorage.getItem("i"))
    setAsistencia(response.data)

  }
  return (
    <div>
      <table className="table table-success table-hover table-striped">
        <thead>
          <tr className="text-center">
            <th> fecha </th>
            <th> turno </th>
            <th> estado </th>
            <th> justificacion </th>
          </tr>
        </thead>
        <tbody>

          {
            asistencia.map((asis) => (
              <tr className="text-center" key={asis.idAsistencia}>
                <td data-title="fecha:"> {moment(asis.fecha).utc().format("DD/MM/YYYY")} </td>
                <td data-title="turno:"> {asis.turno} </td>
                <td data-title="estado:"> {asis.estado} </td>
                <td data-title="justificacion:"> {asis.justificacion} </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}
